

































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThingsFeatureEntityModel, ThingsFeatureQueryModel } from '@common-src/entity-model/things-feature-entity';
import ThingsFeatureService from '@common-src/service/things-feature';
import ThingsFeatureDetailDrawer from './things-feature-detail-drawer.vue';

@Component({
    components: {
        'things-feature-detail-drawer': ThingsFeatureDetailDrawer
    }
})
export default class ThingsTemplateListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    ThingsFeatureEntityModel = ThingsFeatureEntityModel;

    created() {
        this.initTable({
            service: ThingsFeatureService,
            queryModel: new ThingsFeatureQueryModel(),
            tableColumns: ThingsFeatureEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as ThingsFeatureQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/things-feature-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/things-feature-list');
        }
    }
    detailClick(model: ThingsFeatureEntityModel) {
        (this.$refs.thingsFeatureDetailDrawer as ThingsFeatureDetailDrawer).drawerShow(model);
    }
}

